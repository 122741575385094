import React from 'react';
import './Services.css'; // Подключаем стили



const services = [
  { title: 'Уборка квартир', description: 'Профессиональная уборка квартир в удобное для вас время.' },
  { title: 'Уборка офисов', description: 'Чистота и порядок в вашем офисе с нашей помощью.' },
  { title: 'Генеральная уборка', description: 'Генеральная уборка для вашего дома или офиса.' },
  { title: 'Мойка окон', description: 'Чистые окна и зеркала без разводов.' },
  { title: 'Химчистка ковров', description: 'Глубокая чистка ковров с использованием лучших средств.' }
];

const Services = () => {
  return (
    <div className="services-container">
      {services.map((service, index) => (
        <div key={index} className="service-card">
          <h3>{service.title}</h3>
          <p>{service.description}</p>
        </div>
      ))}
    </div>
  );
};

export default Services;
