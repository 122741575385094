import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer bg-light py-3">
      <div className="container d-flex justify-content-between align-items-center">
        <p className="mb-0">© 2023 Клининговая компания в Алматы</p>
      </div>
    </footer>
  );
};

export default Footer;
