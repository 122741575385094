import React, { useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

const OrderEditModal = ({ order, onClose, onSave }) => {
  console.log('Модальное окно открыто для редактирования заказа:', order);

  const [formData, setFormData] = useState({
    name: order.name,
    phone: order.phone,
    email: order.email,
    service: order.service,
    date: order.date,
    time: order.time,
  });

  const [errors, setErrors] = useState({}); // Состояние для ошибок валидации

  const validate = () => {
    let errors = {};
    if (!formData.name.trim()) {
      errors.name = 'Имя обязательно';
    }
    if (!formData.phone.match(/^\d+$/)) {
      errors.phone = 'Телефон должен содержать только цифры';
    }
    if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Некорректный формат email';
    }
    if (!formData.service.trim()) {
      errors.service = 'Услуга обязательна';
    }
    if (!formData.date) {
      errors.date = 'Дата обязательна';
    }
    if (!formData.time) {
      errors.time = 'Время обязательно';
    }
    return errors;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const token = localStorage.getItem('token');
    axios.put(`http://localhost:5000/admin/orders/${order._id}`, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
    .then(() => {
      console.log('Заказ успешно сохранен');
      onSave();
      onClose();
    })
    .catch((error) => {
      console.error('Ошибка при сохранении заказа:', error);
    });
  };

  return (
    <div className="modal" style={{ display: 'flex' }}>
      <div className="modal-content">
        <h2>Редактировать заказ</h2>
        {/* Поля формы */}
        <div className="form-group">
          <label>Имя</label>
          <input type="text" name="name" value={formData.name} onChange={handleChange} className="form-control" />
          {errors.name && <div className="error">{errors.name}</div>}
        </div>
        <div className="form-group">
          <label>Телефон</label>
          <input type="tel" name="phone" value={formData.phone} onChange={handleChange} className="form-control" />
          {errors.phone && <div className="error">{errors.phone}</div>}
        </div>
        <div className="form-group">
          <label>Email</label>
          <input type="email" name="email" value={formData.email} onChange={handleChange} className="form-control" />
          {errors.email && <div className="error">{errors.email}</div>}
        </div>
        <div className="form-group">
          <label>Услуга</label>
          <input type="text" name="service" value={formData.service} onChange={handleChange} className="form-control" />
          {errors.service && <div className="error">{errors.service}</div>}
        </div>
        <div className="form-group">
          <label>Дата</label>
          <input type="date" name="date" value={formData.date} onChange={handleChange} className="form-control" />
          {errors.date && <div className="error">{errors.date}</div>}
        </div>
        <div className="form-group">
          <label>Время</label>
          <input type="time" name="time" value={formData.time} onChange={handleChange} className="form-control" />
          {errors.time && <div className="error">{errors.time}</div>}
        </div>
        <button onClick={handleSave} className="btn btn-primary">Сохранить</button>
        <button onClick={onClose} className="btn btn-secondary">Отмена</button>
      </div>
    </div>
  );
};

OrderEditModal.propTypes = {
  order: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default OrderEditModal;
