// Home.js
import React from 'react';
import ServiceSection from './ServiceSection'; // Создадим этот компонент
import apartmentCleaningImage from './images/apartment-cleaning.jpg';
import officeCleaningImage from './images/office-cleaning.jpg';
import './Home.css';

const Home = () => {
  return (
    <div className="home-container">
      <header className="home-header">
        <h1 className="main-heading">Профессиональные услуги по уборке и химчистке</h1>
      </header>
      <section className="services-section">
        <ServiceSection
          title="Уборка квартир"
          description="Профессиональная уборка квартир в удобное для вас время."
          image={apartmentCleaningImage}
          imagePosition="right"
        />
        <ServiceSection
          title="Уборка офисов"
          description="Чистота и порядок в вашем офисе с нашей помощью."
          image={officeCleaningImage}
          imagePosition="left"
        />
        {/* Добавьте дополнительные секции услуг по необходимости */}
      </section>
    </div>
  );
};

export default Home;
