import React, { useState } from 'react';
import axios from 'axios';
import './Contact.css'; // Подключаем стили для формы

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('http://localhost:5000/send', formData)
      .then(() => {
        alert('Спасибо! Мы свяжемся с вами в ближайшее время.');
        setFormData({
          name: '',
          email: '',
          message: '',
        });
      })
      .catch(() => {
        alert('Ошибка при отправке сообщения.');
      });
  };

  return (
    <div className="form-container"> {/* Центрируем форму */}
      <main>
        <h1>Свяжитесь с нами</h1>
        <form onSubmit={handleSubmit}>
          <label>
            Имя:
            <input type="text" name="name" value={formData.name} onChange={handleChange} required />
          </label>
          <label>
            Email:
            <input type="email" name="email" value={formData.email} onChange={handleChange} required />
          </label>
          <label>
            Сообщение:
            <textarea name="message" value={formData.message} onChange={handleChange} required />
          </label>
          <button type="submit">Отправить</button>
        </form>
      </main>
    </div>
  );
};

export default Contact;
