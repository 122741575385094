import React, { useState } from 'react';
import './OrderForm.css';
import axios from 'axios'; // Импорт axios для отправки данных
import { FaBuilding, FaHome } from 'react-icons/fa';

const OrderForm = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    service: '',
    date: '',
    time: '',
    propertyType: '', // Тип помещения
    cleaningType: '', // Тип уборки
  });

  // Функция для выбора типа помещения
  const handlePropertyTypeSelect = (type) => {
    setFormData({ ...formData, propertyType: type });
  };

  // Функция для выбора типа уборки
  const handleCleaningTypeSelect = (type) => {
    setFormData({ ...formData, cleaningType: type });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleNextStep = () => {
    if (step < 3) setStep(step + 1);
  };

  const handlePreviousStep = () => {
    if (step > 1) setStep(step - 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Отправка данных на сервер
    axios.post('http://localhost:5000/order', formData)
      .then((response) => {
        alert('Спасибо! Ваш заказ принят.');
        setFormData({
          name: '',
          phone: '',
          email: '',
          service: '',
          date: '',
          time: '',
          propertyType: '',
          cleaningType: ''
        });
      })
      .catch((error) => {
        console.error('Ошибка при отправке заказа:', error);
        alert('Ошибка при отправке заказа.');
      });
  };

  return (
    <div className="order-page-container">
      <div className="order-form">
        {step === 1 && (
          <div className="form-step">
            <h2 className="step-title">Шаг 1:<br /> Выберите тип помещения</h2>
            <p className="question">Где вы живете?</p>
            <div className="property-type-container">
              <div
                className={`property-option ${formData.propertyType === 'apartment' ? 'selected' : ''}`}
                onClick={() => handlePropertyTypeSelect('apartment')}
              >
                <FaBuilding size={36} />
                <span>Квартира</span>
              </div>
              <div
                className={`property-option ${formData.propertyType === 'house' ? 'selected' : ''}`}
                onClick={() => handlePropertyTypeSelect('house')}
              >
                <FaHome size={36} />
                <span>Частный дом</span>
              </div>
            </div>

            <h3 className="sub-title">Тип уборки</h3>
            <div className="cleaning-type-container">
              <button
                className={`cleaning-option ${formData.cleaningType === 'standard' ? 'selected' : ''}`}
                onClick={() => handleCleaningTypeSelect('standard')}
              >
                Стандартная
              </button>
              <button
                className={`cleaning-option ${formData.cleaningType === 'general' ? 'selected' : ''}`}
                onClick={() => handleCleaningTypeSelect('general')}
              >
                Генеральная
              </button>
              <button
                className={`cleaning-option ${formData.cleaningType === 'post-renovation' ? 'selected' : ''}`}
                onClick={() => handleCleaningTypeSelect('post-renovation')}
              >
                После ремонта
              </button>
            </div>

            <button onClick={handleNextStep} className="next-button">Далее</button>
          </div>
        )}

        {step === 2 && (
          <div className="form-step">
            <h2>Шаг 2:<br></br> Контактные данные</h2>
            <label>Имя:</label>
            <input type="text" name="name" value={formData.name} onChange={handleChange} required />
            <label>Телефон:</label>
            <input type="tel" name="phone" value={formData.phone} onChange={handleChange} required />
            <label>Email:</label>
            <input type="email" name="email" value={formData.email} onChange={handleChange} required />
            <label>Услуга:</label>
            <input type="text" name="service" value={formData.service} onChange={handleChange} required />
            <button onClick={handlePreviousStep} className="back-button">Назад</button>
            <button onClick={handleNextStep} className="next-button">Далее</button>
          </div>
        )}

        {step === 3 && (
          <div className="form-step">
            <h2>Шаг 3: Подтверждение заказа</h2>
            <label>Дата:</label>
            <input type="date" name="date" value={formData.date} onChange={handleChange} required />
            <label>Время:</label>
            <input type="time" name="time" value={formData.time} onChange={handleChange} required />
            <button onClick={handlePreviousStep} className="back-button">Назад</button>
            <button type="submit" onClick={handleSubmit} className="submit-button">Оформить заказ</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderForm;
