import React, { useState } from 'react';
import axios from 'axios';

const AdminLogin = () => {
  const [formData, setFormData] = useState({ username: '', password: '' });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios.post('http://localhost:5000/admin/login', formData)
      .then((response) => {
        // Сохраняем токен в localStorage
        localStorage.setItem('token', response.data.token);
        // Перенаправляем на страницу заказов
        window.location.href = '/admin/orders';
      })
      .catch((error) => {
        console.error('Ошибка при входе:', error);
        alert('Неверные учетные данные');
      });
  };

  return (
    <div>
      <h2>Вход администратора</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Логин:
          <input type="text" name="username" value={formData.username} onChange={handleChange} required />
        </label>
        <br />
        <label>
          Пароль:
          <input type="password" name="password" value={formData.password} onChange={handleChange} required />
        </label>
        <br />
        <button type="submit">Войти</button>
      </form>
    </div>
  );
};

export default AdminLogin;
