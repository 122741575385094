// ServiceSection.js
import React from 'react';
import './ServiceSection.css';

const ServiceSection = ({ title, description, image, imagePosition }) => {
  return (
    <div className={`service-section ${imagePosition === 'left' ? 'reverse' : ''}`}>
      <div className="service-text">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
      <div className="service-image">
        <img src={image} alt={title} />
      </div>
    </div>
  );
};

export default ServiceSection;
