import React from 'react';

const ApartmentCleaning = () => (
  <main>
    <h1>Уборка квартир</h1>
    <p>Подробное описание услуги...</p>
    <p>Цена: от 10 000 тг</p>
    {/* Добавьте изображения и другие детали */}
  </main>
);

export default ApartmentCleaning;
