import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const Header = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light custom-navbar">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">Клининговая компания</Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-between" id="navbarNav">
          <div className="navbar-left">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link" to="/">Главная</Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to="/orderform">Заказать</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact">Контакты</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/admin/orders">Админка</Link>
              </li>
            </ul>
          </div>
          <div className="navbar-right d-flex align-items-center">
            <div className="navbar-info me-3">
              <span><FontAwesomeIcon icon={faPhone} /> +7 (777) 187 75 75</span>
              <br />
              <span><FontAwesomeIcon icon={faPhone} /> +7 (777) 837 70 70</span>
            </div>
            <a href="https://wa.me/77771877575" target="_blank" rel="noopener noreferrer" className="btn btn-success ms-3 whatsapp-btn">
              <FontAwesomeIcon icon={faWhatsapp} /> WhatsApp
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
