import React from 'react';
import OrderForm from '../components/OrderForm';

const OrderPage = () => (
  <main>
    <h1>Сделать заказ</h1>
    <OrderForm />
  </main>
);

export default OrderPage;
