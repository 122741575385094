import React, { useEffect, useState } from 'react';
import axios from 'axios';
import OrderEditModal from '../components/OrderEditModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AdminOrders = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]); // Состояние для фильтрованных заказов
  const [selectedOrder, setSelectedOrder] = useState(null); // Состояние для выбранного заказа
  const [isEditing, setIsEditing] = useState(false); // Состояние для отображения модального окна
  const [comment, setComment] = useState(''); // Поле для комментариев
  const [currentOrderId, setCurrentOrderId] = useState(null); // Для отслеживания заказа, к которому добавляется комментарий

  // Состояние для фильтрации и сортировки
  const [filterName, setFilterName] = useState('');
  const [filterService, setFilterService] = useState('');
  const [filterDate, setFilterDate] = useState('');
  const [sortField, setSortField] = useState(''); // Поле для сортировки
  const [sortOrder, setSortOrder] = useState('asc'); // Порядок сортировки: 'asc' или 'desc'

  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    applyFilters(); // Применяем фильтры и сортировку при изменении данных
  }, [orders, filterName, filterService, filterDate, sortField, sortOrder]);

  const fetchOrders = () => {
    const token = localStorage.getItem('token'); // Получаем токен из localStorage

    axios.get('http://localhost:5000/admin/orders', {
      headers: {
        'Authorization': `Bearer ${token}`, // Добавляем токен в заголовки запроса
      },
    })
    .then((response) => {
      setOrders(response.data); // Сохраняем заказы в состоянии
    })
    .catch((error) => {
      console.error('Ошибка при получении заказов:', error);
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        window.location.href = '/admin/login';
      }
    });
  };

  const applyFilters = () => {
    let filtered = [...orders];

    // Фильтр по имени
    if (filterName) {
      filtered = filtered.filter(order => order.name.toLowerCase().includes(filterName.toLowerCase()));
    }

    // Фильтр по услуге
    if (filterService) {
      filtered = filtered.filter(order => order.service.toLowerCase().includes(filterService.toLowerCase()));
    }

    // Фильтр по дате
    if (filterDate) {
      filtered = filtered.filter(order => new Date(order.date).toLocaleDateString() === new Date(filterDate).toLocaleDateString());
    }

    // Сортировка
    if (sortField) {
      filtered.sort((a, b) => {
        const valueA = a[sortField];
        const valueB = b[sortField];

        if (sortOrder === 'asc') {
          return valueA > valueB ? 1 : -1;
        } else {
          return valueA < valueB ? 1 : -1;
        }
      });
    }

    setFilteredOrders(filtered);
  };

  const handleEdit = (order) => {
    setSelectedOrder(order); // Устанавливаем выбранный заказ
    setIsEditing(true); // Показываем модальное окно
  };

  const handleDelete = (id) => {
    const token = localStorage.getItem('token');
    
    axios.delete(`http://localhost:5000/admin/orders/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
    .then(() => {
      setOrders(orders.filter(order => order._id !== id)); // Обновляем список заказов после удаления
      toast.success('Заказ удален успешно');
    })
    .catch((error) => {
      console.error('Ошибка при удалении заказа:', error);
      toast.error('Ошибка при удалении заказа');
    });
  };

  const handleSave = () => {
    fetchOrders(); // Обновление заказов после редактирования
    setIsEditing(false); // Закрытие модального окна после сохранения
    toast.success('Заказ успешно обновлен');
  };

  // Функция для добавления комментария
  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleAddComment = (orderId) => {
    const token = localStorage.getItem('token');
    
    axios.put(`http://localhost:5000/admin/orders/${orderId}/comment`, { comments: comment }, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
    .then(() => {
      toast.success('Комментарий добавлен');
      setComment('');  // Очищаем поле комментария после добавления
      setCurrentOrderId(null); // Очищаем выбранный заказ для комментария
      fetchOrders();  // Обновляем заказы после добавления комментария
    })
    .catch((error) => {
      toast.error('Ошибка при добавлении комментария');
    });
  };
  

  // Функция для отметки заказа как выполненного
  const handleCompleteOrder = (orderId) => {
    const token = localStorage.getItem('token');
    axios.put(`http://localhost:5000/admin/orders/${orderId}/complete`, {}, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
    .then(() => {
      toast.success('Заказ отмечен как выполненный');
      fetchOrders();  // Обновляем заказы после изменения статуса
    })
    .catch((error) => {
      toast.error('Ошибка при обновлении статуса заказа');
    });
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  return (
    <div className="container">
      <ToastContainer />
      <h1 className="mt-4">Список заказов</h1>
      
      {/* Фильтры */}
      <div className="filters mb-4">
        <input
          type="text"
          placeholder="Фильтр по имени"
          value={filterName}
          onChange={(e) => setFilterName(e.target.value)}
          className="form-control mb-2"
        />
        <input
          type="text"
          placeholder="Фильтр по услуге"
          value={filterService}
          onChange={(e) => setFilterService(e.target.value)}
          className="form-control mb-2"
        />
        <input
          type="date"
          placeholder="Фильтр по дате"
          value={filterDate}
          onChange={(e) => setFilterDate(e.target.value)}
          className="form-control mb-2"
        />
      </div>

      {/* Кнопка "Выйти" */}
      <button onClick={() => {
        localStorage.removeItem('token');
        window.location.href = '/admin/login';
      }} className="btn btn-danger mb-4">Выйти</button>

      {/* Таблица заказов */}
      <table className="table table-striped">
      <thead>
  <tr>
    <th onClick={() => handleSort('name')}>Имя</th>
    <th>Телефон</th>
    <th>Почта</th>
    <th onClick={() => handleSort('service')}>Услуга</th>
    <th>Тип помещения</th> {/* Существующее поле */}
    <th>Тип уборки</th> {/* Новое поле для типа уборки */}
    <th onClick={() => handleSort('date')}>Дата</th>
    <th>Время</th>
    <th>Комментарий</th>
    <th>Статус</th>
    <th>Время создания</th> {/* Новое поле */}
    <th>Действия</th>
  </tr>
</thead>
<tbody>
  {filteredOrders.map((order) => (
    <tr key={order._id}>
      <td>{order.name}</td>
      <td>{order.phone}</td>
      <td>{order.email}</td>
      <td>{order.service}</td>
      <td>{order.propertyType === 'apartment' ? 'Квартира' : 'Частный дом'}</td> {/* Отображение типа помещения */}
      <td>{order.cleaningType === 'standard' ? 'Стандартная' : order.cleaningType === 'general' ? 'Генеральная' : 'После ремонта'}</td> {/* Отображение типа уборки */}
      <td>{new Date(order.date).toLocaleDateString()}</td>
      <td>{order.time}</td>
      <td>
        {/* Поле для ввода комментария для каждого заказа */}
        <input
          type="text"
          value={currentOrderId === order._id ? comment : ''}
          onChange={handleCommentChange}
          onFocus={() => setCurrentOrderId(order._id)} // Устанавливаем текущий заказ для ввода комментария
          placeholder={currentOrderId === order._id ? '' : order.comments || 'Добавить комментарий'} // Очищаем плейсхолдер при фокусе
          className="form-control"
        />
        <button onClick={() => handleAddComment(order._id)} className="btn btn-primary btn-sm mt-2">Добавить комментарий</button>

        {/* Отображение комментария под заказом */}
        {order.comments && <p className="mt-2">{order.comments}</p>}
      </td>
      <td>{order.isCompleted ? 'Выполнен' : 'В процессе'}</td>
      <td>{new Date(order.createdAt).toLocaleString()}</td> {/* Время создания */}
      <td>
        {!order.isCompleted && (
          <button onClick={() => handleCompleteOrder(order._id)} className="btn btn-success btn-sm">Отметить как выполненный</button>
        )}
        <button onClick={() => handleEdit(order)} className="btn btn-warning btn-sm">Редактировать</button>
        <button onClick={() => handleDelete(order._id)} className="btn btn-danger btn-sm ml-2">Удалить</button>
      </td>
    </tr>
  ))}
</tbody>

      </table>

      {isEditing && selectedOrder && (
        <OrderEditModal
          order={selectedOrder}
          onClose={() => setIsEditing(false)}
          onSave={handleSave}
        />
      )}
    </div>
  );
};

export default AdminOrders;
