import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Services from './pages/Services';
import Contact from './pages/Contact';
import './App.css';
import ApartmentCleaning from './pages/ApartmentCleaning';
import OrderPage from './pages/OrderPage';
import AdminOrders from './pages/AdminOrders';
import AdminLogin from './pages/AdminLogin';
import ProtectedRoute from './components/ProtectedRoute';
import OrderForm from './components/OrderForm';



const App = () => (
  <Router>
    <Header />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/services" element={<Services />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/services/apartment-cleaning" element={<ApartmentCleaning />} />
      <Route path="/order" element={<OrderPage />} />
      <Route path="/admin/login" element={<AdminLogin />} />
      <Route path='/orderform' element={<OrderForm />} />
      <Route path="/admin/orders" element={<ProtectedRoute element={AdminOrders} />} />
    </Routes>
    <Footer />
  </Router>
);

export default App;
